import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';

import { useAuth0 } from "@auth0/auth0-react";

const uri = 'https://light-goshawk-72.hasura.app/v1/graphql';

const AuthorizedApolloProvider = ({ children } : {children: any}) => {
  const { getIdTokenClaims } = useAuth0();

  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async () => {
    const { __raw: id_token } = await getIdTokenClaims();

    return {
      headers: {
        Authorization: `Bearer ${id_token}`
      }
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;
