import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";

// routes
import routes, { renderRoutes } from './routes';
// theme
import ThemeConfig from './theme';
// components
import LoadingScreen from './components/LoadingScreen';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

const onRedirectCallback = (appState : any) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

function AppRouter() {
  return (
    <Router history={history}>
      {renderRoutes(routes)}
    </Router>
  );
}

const AppRouterAuth = withAuthenticationRequired(AppRouter, {
  onRedirecting: () => <LoadingScreen />,
});

export default function App() {
  return (
    <Auth0Provider
      domain="jungsoft.eu.auth0.com"
      clientId="g6s09ELGMyHC1VqL7yHH6jZ0gkpVbplg"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="https://jungsoft.eu.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens
    >
      <AuthorizedApolloProvider>
        <HelmetProvider>
          <ThemeConfig>
            <AppRouterAuth />
          </ThemeConfig>
        </HelmetProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  );
}
