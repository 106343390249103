import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.overview,
      component: () => <Redirect to={`/overview/${new Date().getUTCFullYear()}.${new Date().getUTCMonth()+1}/cto-as-a-service`} />
    },
    {
      exact: false,
      path: PATH_DASHBOARD.general.overview,
      component: lazy(() => import('../views/Overview'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.billing,
      component: lazy(() => import('../views/Billing'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.accountSettings,
      component: lazy(() => import('../views/AccountSettings'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.profile,
      component: lazy(() => import('../views/Profile'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.overview} />
    },

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
