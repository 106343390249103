// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    overview: path(ROOTS_DASHBOARD, '/overview/:month'),
    billing: path(ROOTS_DASHBOARD, '/billing'),
    accountSettings: path(ROOTS_DASHBOARD, '/account-settings'),
    profile: path(ROOTS_DASHBOARD, '/profile')
  },
};
