import { useEffect, useState } from 'react';
// material
import { Icon } from '@iconify/react';
import dashboard from '@iconify/icons-eva/pie-chart-outline';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import {
  Tab,
  Tabs,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const ACCOUNT_TABS = [
  {
    path: '/overview',
    value: 'Overview',
    icon: <Icon icon={dashboard} width={20} height={20} />,
  },
  {
    path: '/billing',
    value: 'Billing',
    icon: <Icon icon={roundReceipt} width={20} height={20} />,
  },
  {
    path: '/account-settings',
    value: 'Account Settings',
    icon: <Icon icon={roundAccountBox} width={20} height={20} />,
  },
];

// ----------------------------------------------------------------------

export default function TabsComponent() {
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(`/${location.pathname.split('/')[1] || 'overview'}`);

  function changeTab(value: string) {
    setCurrentTab(value);
    history.push(value);
  }

  useEffect(() => {
    // runs on location, i.e. route, change
    setCurrentTab(`/${location.pathname.split('/')[1]}`);
  }, [location])

  return (
    <Tabs
      value={currentTab === '/' ? false : currentTab}
      onChange={(e, value) => changeTab(value)}
    >
      {ACCOUNT_TABS.map((tab) => (
        <Tab
          disableRipple
          key={tab.path}
          label={tab.value}
          icon={tab.icon}
          value={tab.path}
        />
      ))}
    </Tabs>
  );
}
